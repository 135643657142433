<template>
  <div>
    <page-heading :heading="$t('assets')">
      <template v-slot:actions>
        <app-button :label="$t('create')" @click="openCreateModal()"></app-button>
        <app-options>
          <app-option :label="$t('import')" svg="upload" @click="openModal('import')"/>
          <app-option :label="$t('export')" svg="document-download" @click="exportAssets" v-show="items.length"/>
        </app-options>
      </template>
    </page-heading>

    <debug>{{ query }}</debug>

    <form-open class="grid xl:grid-cols-4 lg:grid-cols-2 gap-4 mb-10" @submit="submit">
      <form-text
        :label="$t('search')"
        :placeholder="$t('asset_search')"
        :required="false"
        :show-validation-label="false"
        icon="search"
        input-id="search"
        v-model="query.filter.search"
      />
      <form-select
        :input="submit"
        :label="$t('status')"
        :multiple="true"
        :options="data.statuses"
        :placeholder="$t('select_placeholder_all')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        input-id="status"
        v-model="query.filter.status"
      />
      <form-select
        :label="$t('category')"
        :multiple="true"
        :options="data.categories"
        :placeholder="$t('select_placeholder_all')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        input-id="categories"
        v-model="query.filter.category"
      />
      <form-select
        :label="$t('view')"
        :options="views"
        input-id="view"
        v-model="view"
      />
      <form-select
        :label="$t('per_page')"
        :options="[
          {
            value: '10',
            label: '10',
          },
          {
            value: '25',
            label: '25',
          },
          {
            value: '100',
            label: '100',
          },
          {
            value: '-1',
            label: $t('select_placeholder_all'),
          },
        ]"
        :required="false"
        input-id="view"
        v-model="query.per_page"
        v-show="false"
      />
      <app-button class="hidden"/>
    </form-open>

    <loading-wrapper>
      <template v-if="view === 'list'">
        <app-table :items="items">
          <template
            v-slot:headings
          >
            <app-th-sortable :label="$t('model.asset.name')" name="name" :sort="query.sort" @updateSort="updateSort"/>
            <app-th-sortable :label="$t('model.asset.status')" name="status" :sort="query.sort" @updateSort="updateSort"/>
            <app-th :heading="$t('model.asset.reports')"/>
            <app-th :heading="$t('model.asset.notes')"/>
            <app-th :heading="$t('model.asset.location')"/>
            <app-th/>
          </template>

          <template
            v-slot:row="{ item }"
          >
            <app-td>
              <a href="#" class="block link" @click.prevent="editModel(item.id)">{{ item.name }}</a>
              <span class="text-xs text-gray-400">{{ item.ref }}</span>
            </app-td>
            <app-td>
              <badge class="text-sm" :theme="getAssetStatusTheme(item.status)" :label="$t(`asset_status.${item.status}`)"/>
            </app-td>
            <app-td>
              <template v-if="$can('manage_asset_reports')">
                <a href="#" class="link" @click.prevent="goToAssetReports(item.ref)">
                  <badge
                    :label="item.reports_count"
                    :theme="getAssetReportsCountBadgeTheme(item.reports_action_req_count)"
                  />
                </a>
              </template>
              <template v-else>
                <badge
                  :label="item.reports_count"
                  :theme="getAssetReportsCountBadgeTheme(item.reports_action_req_count)"
                />
              </template>
            </app-td>
            <app-td>
              <badge
                :label="item.notes_count"
                @click="editModel(item.id, 'notes')"
                class="cursor-pointer"
              />
            </app-td>
            <app-td>
              <template v-if="item.location">
                <app-svg
                  @click="goToAssetMapLocation(item.id)"
                  class="h-4 cursor-pointer hover:text-app-orange"
                  svg="location-marker"
                />
              </template>
              <template v-else>
                {{ $t('na') }}
              </template>
            </app-td>
            <app-td>
              <app-options>
                <app-option-edit @click="editModel(item.id)"/>
                <app-option-delete @click="openDeleteModal(item.id)"/>
              </app-options>
            </app-td>
          </template>
        </app-table>
      </template>
      <template v-if="view === 'map'">
        <div class="" style="height: 400px;">
          <gmap-map
            :center="googleMap.mapCenter"
            :zoom="googleMap.zoom"
            class="w-full h-full"
            ref="googleMap"
          >
            <gmap-info-window
              :opened="googleMap.infoWinOpen"
              :options="googleMap.infoWinOptions"
              :position="googleMap.infoWinPos"
              @closeclick="googleMap.infoWinOpen = false"
            >
              <template v-if="googleMap.infoWinContent">
                <a href="#" @click="editModel(googleMap.infoWinContent.asset.id)">
                  <h4 class="text-md font-semibold">{{ googleMap.infoWinContent.asset.name }}</h4>
                  <span class="inline-block">{{ googleMap.infoWinContent.asset.ref }}</span>
                </a>
              </template>
            </gmap-info-window>

            <gmap-cluster>
              <gmap-marker
                :key="index"
                :position="m.position"
                @clickable="true"
                @click="toggleInfoWindow(m, index)"
                v-for="(m, index) in markers"
              />
            </gmap-cluster>
          </gmap-map>
        </div>
      </template>
      <pagination
        :meta="meta"
        :page="query.page"
        v-if="meta"
        @pageUpdated="updatePage"
      />
    </loading-wrapper>

    <slideover :active="modals.create" @close="closeModal('create')" @submit="createModel"
      :title="$t('create')"
    >
      <debug>{{ model }}</debug>
      <div class="grid grid-cols-2 gap-4">
        <form-text
          :form-id="formId"
          :label="$t('model.asset.name')"
          class="col-span-full"
          form-error-id="name"
          input-id="name"
          v-model="model.name"
        />
        <form-text
          :form-id="formId"
          :label="$t('model.asset.ref')"
          @input="sluggifyRef = false"
          class="col-span-full"
          form-error-id="ref"
          icon="qrcode"
          input-id="ref"
          v-model="model.ref"
        />
      </div>

      <template v-slot:buttons>
        <app-button
          :label="$t('create')"
          :disabled="loading"
          :loading="loading"
        />
      </template>
    </slideover>

    <modal :active="modals.import" @close="closeImportModal">
      <div class="w-full space-y-2">
        <validation-errors :form-id="formIds.importAssets"/>

        <template v-if="importFeedback">
          <alert theme="success">
            <h5 class="font-semibold">{{ $t('import_assets_rows_processed_heading') }}</h5>
            <span>{{ $t('import_assets_rows_processed', { processed: importFeedback.processed, rows: importFeedback.rows }) }}</span>
          </alert>
          <alert class="max-h-20 overflow-auto" theme="warning" v-if="Object.keys(importFeedback.errors).length">
            <h5 class="font-semibold">{{ $t('import_assets_rows_problems_heading') }}</h5>
            <ul>
              <li v-for="(fields, row) in importFeedback.errors" :key="row">
                <ul>
                  <span>{{ $t('import_assets_row', { row: row }) }}</span>
                  <li v-for="(errors, field) in fields" :key="field">
                    <ul>
                      <li v-for="(error, i) in errors" :key="i">{{ error }}</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </alert>
        </template>

        <form-files
          :files="importFile"
          :multiple="false"
          :server="{
            process: importAssets,
          }"
          class="w-full"
          name="file"
        />
      </div>
    </modal>

    <modal-delete :active="modals.delete" @close="closeModal('delete')" @delete="deleteModel"
      :title="$t('delete')"
      :text="$t('delete_thing_text', { thing: model.name })"
    />
  </div>
</template>

<script>
import ApiAssetCategoryService from '@/services/api/asset_category';
import ApiAssetService from '@/services/api/asset';
import ApiAssetStatusService from '@/services/api/asset_status';
import Common from '@/mixins/common';
import config from '@/config';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
// eslint-disable-next-line no-unused-vars
import { gmapApi } from 'vue2-google-maps';
import { saveAs } from 'file-saver';

export default {
  components: {
    GmapCluster,
  },
  computed: {
    views() {
      const views = [
        {
          value: 'list',
          label: this.$t('list'),
        },
      ];

      if (this.markers.length) {
        views.push({
          value: 'map',
          label: this.$t('map'),
        });
      }

      return views;
    },
  },
  data() {
    return {
      data: {
        statuses: [],
        categories: [],
      },
      importFeedback: null,
      importFile: [],
      formId: 'create-asset',
      formIds: {
        importAssets: 'importAssets',
      },
      googleMap: {
        currentMarkerIndex: null,
        infoWinOpen: false,
        infoWinOptions: {
          pixelOffset: { // So the info window sits nicely on top of the marker.
            width: 0,
            height: -35,
          },
        },
        infoWinContent: null,
        infoWinPos: null,
        mapCenter: config.app.googleMapDefaults.center,
        markers: [],
        zoom: config.app.googleMapDefaults.zoom,
      },
      items: [],
      markers: [],
      meta: null,
      modals: {
        import: false,
        create: false,
        delete: false,
      },
      model: this.emptyModel(),
      notes: {
        items: [],
        meta: {},
      },
      query: {
        filter: {
          status: [
            1,
          ],
        },
        page: 1,
        per_page: config.app.pagination.per_page,
        sort: [
          'name',
        ],
        ...this.$route.query,
      },
      sluggifyRef: true,
      view: 'list',
    };
  },
  methods: {
    closeImportModal() {
      this.importFeedback = null;
      this.importFile = [];
      this.closeModal('import');
    },
    createModel() {
      ApiAssetService.createOrganisationAsset(this.model, {
        formId: this.formId,
        showMessage: true,
      })
        .then((response) => {
          const { data } = response.data;

          this.$router.push({
            name: 'assets.edit',
            params: {
              assetId: data.id,
            },
          });
        })
        .catch(() => {});
    },
    deleteModel() {
      ApiAssetService.deleteOrganisationAsset(this.modelId, {
        showMessage: true,
      })
        .then(() => {
          this.closeModals();
          this.getListings();
        })
        .catch(() => {});
    },
    editModel(modelId, hash = null) {
      const location = {
        name: 'assets.edit',
        params: {
          assetId: modelId,
        },
      };

      if (hash) {
        location.hash = `#${hash}`;
      }

      this.$router.push(location);
    },
    emptyModel() {
      return {};
    },
    exportAssets() {
      ApiAssetService.exportOrganisationAssets({
        params: this.query,
        responseType: 'blob',
      }).then((response) => {
        saveAs(response.data, `assets_${Date.now()}.csv`);
      });
    },
    getAssetStatusTheme(assetStatusId) {
      return assetStatusId === 1 ? 'success' : 'warning';
    },
    getListings() {
      ApiAssetService.getOrganisationAssets({
        params: {
          ...this.query,
          include: [
            'location',
            // 'locations',
            'media',
            'notesCount',
            'reportsActionReqCount',
            'reportsCount',
          ],
        },
      }).then((response) => {
        const { data, meta } = response.data;

        this.items = data;
        this.meta = meta;

        const markers = [];
        data.forEach((asset) => {
          if (asset.location) {
            markers.push({
              asset,
              position: {
                lat: asset.location.lat,
                lng: asset.location.lng,
              },
            });
          }
        });
        this.markers = markers;
      }).catch(() => {});
    },
    getAndSetModel(modelId) {
      return ApiAssetService.getOrganisationAsset(modelId)
        .then((response) => {
          const { data } = response.data;

          this.modelId = data.id;
          this.model = this.resourceToModel(data);
        });
    },
    getAssetReportsCountBadgeTheme(count) {
      return count ? 'error' : 'light';
    },
    goToAssetMapLocation(assetId) {
      this.editModel(assetId, 'location');
    },
    goToAssetReports(assetRef) {
      this.$router.push({
        name: 'asset_reports',
        query: {
          filter: {
            assetRef,
          },
        },
      });
    },
    // eslint-disable-next-line no-unused-vars
    importAssets(fieldName, file, metadata, load, error, progress, abort, transfer, options) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      ApiAssetService.importOrganisationAssets(formData, {
        formId: this.formIds.importAssets,
        onUploadProgress: (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        },
        // showMessage: true,
      })
        .then((response) => {
          const { data } = response;

          load();
          this.importFeedback = data;
          this.getListings();
        })
        .catch(() => {});

      return {
        abort: () => {
          abort();
        },
      };
    },
    openCreateModal() {
      this.modelId = null;
      this.model = this.emptyModel();

      this.openModal('create');
    },
    openDeleteModal(modelId) {
      this.getAndSetModel(modelId).then(() => {
        this.openModal('delete');
      });
    },
    resourceToModel(resource) {
      return {
        name: resource.name,
      };
    },
    submit() {
      this.query.page = 1;
      this.getListings();
    },
    toggleInfoWindow(marker, i) {
      this.googleMap.infoWinPos = marker.position;
      this.googleMap.infoWinContent = marker;

      if (this.googleMap.currentMarkerIndex === i) {
        this.googleMap.infoWinOpen = !this.infoWinOpen;
      } else {
        this.googleMap.infoWinOpen = true;
        this.googleMap.currentMarkerIndex = i;
      }
    },
  },
  mixins: [
    Common,
  ],
  mounted() {
    // eslint-disable-next-line no-unused-vars
    /*
    this.$refs.googleMap.$mapPromise.then((map) => {
      // console.log(map);
    });
    */

    ApiAssetStatusService.getAssetStatuses()
      .then((response) => {
        const { data } = response.data;

        this.data.statuses = this.toSelectOptions(data, 'status', 'id', (label, value) => `asset_status.${value}`);
      })
      .catch(() => {});

    ApiAssetCategoryService.getAssetCategories({
      params: {
        per_page: -1,
      },
    })
      .then((response) => {
        const { data } = response.data;

        this.data.categories = this.toSelectOptions(data);
      })
      .catch(() => {});

    this.getListings();
  },
  watch: {
    markers(markers) {
      this.googleMap.infoWinOpen = false;

      if (markers.length && this.$refs.googleMap && false) {
        // eslint-disable-next-line new-cap
        // const bounds = new gmapApi().maps.LatLngBounds();

        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds();
        markers.forEach((m) => {
          bounds.extend(m.position);
        });
        this.$refs.googleMap.fitBounds(bounds);
      }

      if (markers.length === 0) {
        this.view = 'list';
      }
    },
    model: {
      handler(model) {
        if (this.sluggifyRef && model.name) {
          this.model.ref = this.appSluggify(model.name);
        }
      },
      deep: true,
    },
    view(newView) {
      const mergeData = newView === 'map' ? {
        per_page: -1,
        page: 1,
      } : {
        per_page: config.app.pagination.per_page,
        page: 1,
      };

      this.query = {
        ...this.query,
        ...mergeData,
      };
      this.getListings();
    },
  },
};
</script>
