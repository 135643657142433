import axios from '@/plugins/axios';

export default {
  getAssetCategories(params = {}) {
    return axios.get('/v1/organisation/asset-categories', params);
  },
  createAssetCategory(data, config = {}) {
    return axios.post('/v1/organisation/asset-categories', data, config);
  },
  updateAssetCategory(assetCategoryId, data, config = {}) {
    return axios.put(`/v1/organisation/asset-categories/${assetCategoryId}`, data, config);
  },
  getAssetCategory(assetCategoryId, config = {}) {
    return axios.get(`/v1/organisation/asset-categories/${assetCategoryId}`, config);
  },
  deleteAssetCategory(assetCategoryId, config = {}) {
    return axios.delete(`/v1/organisation/asset-categories/${assetCategoryId}`, config);
  },
};
